// App.jsx
import { useState } from 'react';
import InstructionsPanel from './components/InstructionsPanel';
import PensionCalculator from './components/PensionCalculator';
import ResultsTable from './components/ResultsTable';
import SupplementaryInfo from './components/SupplementaryInfo';

// App.jsx
function App() {
  const [results, setResults] = useState(null);

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-50 to-white">
      <div className="max-w-[1600px] mx-auto px-4 py-8 sm:px-6 lg:px-8">
        <header className="text-center mb-8">
          <h1 className="text-4xl font-serif text-blue-900 mb-2">
            State Teachers Retirement System
          </h1>
          <h2 className="text-2xl font-serif text-blue-900 mb-4">
            COLA Loss Calculator
          </h2>
        </header>

        {/* New section for the moved text */}
        <div className="max-w-3xl mx-auto mb-12">
          <div className="bg-blue-50 rounded-lg p-4 mb-6">
            <p className="text-gray-600 mb-4 text-center">
            To all STRS retired and active teachers, all Ohio Legislators, the Governor, Attorney General and the Ohio State Auditor
            </p>
          </div>

          <p className="text-gray-600 mb-4 text-center">
          With the following calculator, STRS members can quickly enter three pieces of information regarding pension losses experienced over time.
          </p>
        </div>

        <div className="grid grid-cols-1 xl:grid-cols-12 gap-8">
          {/* Instructions - removed the moved text from InstructionsPanel */}
          <div className="xl:col-span-3 order-1">
            <InstructionsPanel />
          </div>

          {/* Supplementary Information */}
          <div className="xl:col-span-3 xl:order-3 order-2">
            <SupplementaryInfo />
          </div>

          {/* Calculator and Table */}
          <div className="xl:col-span-6 xl:order-2 order-3">
            <div className="bg-white rounded-lg shadow-lg p-6">
              <PensionCalculator onCalculate={setResults} />
              {results && <ResultsTable results={results} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}


export default App;
