// components/InstructionsPanel.jsx
function InstructionsPanel() {
  return (
    <div className="bg-white rounded-lg shadow-lg p-6">
      <div className="prose prose-blue max-w-none">
        <h2 className="text-xl font-medium text-gray-900 mb-4">Instructions</h2>

        <div className="space-y-6">
          {/* Instructions */}
          <div className="space-y-2 text-gray-600">
            <h3 className="font-medium text-gray-900">Data Entry:</h3>
            <ol className="space-y-2 text-gray-700">
              <li className="flex">
                <span className="mr-2">1.</span>
                <span>The first box is the amount of the base benefit (no COLA).</span>
              </li>
              <li className="flex">
                <span className="mr-2">2.</span>
                <span>The second is the rate of COLA increase (3% if retired prior to 2013; 2% thereafter).</span>
              </li>
              <li className="flex">
                <span className="mr-2">3.</span>
                <span>The third is the % of Final Average Salary (FAS), based on years worked.</span>
              </li>
            </ol>
          </div>

          {/* Column Explanations */}
          <div className="space-y-2 text-gray-600">
            <h3 className="font-medium text-gray-900">Column Explanations:</h3>
            <ul className="space-y-1">
              <li className="flex">
                <span className="mr-2">•</span>
                <span><span className="font-semibold text-blue-700 bg-blue-50 px-1.5 py-0.5 rounded">First:</span> years retired without a COLA</span>
              </li>
              <li className="flex">
                <span className="mr-2">•</span>
                <span><span className="font-semibold text-blue-700 bg-blue-50 px-1.5 py-0.5 rounded">Second:</span> starting annual pension, repeated for all years</span>
              </li>
              <li className="flex">
                <span className="mr-2">•</span>
                <span><span className="font-semibold text-blue-700 bg-blue-50 px-1.5 py-0.5 rounded">Third:</span> base pension, multiplied by the years retired (cumulative)</span>
              </li>
              <li className="flex">
                <span className="mr-2">•</span>
                <span><span className="font-semibold text-blue-700 bg-blue-50 px-1.5 py-0.5 rounded">Fourth:</span> annual pension with simple 3% or 2% COLA</span>
              </li>
              <li className="flex">
                <span className="mr-2">•</span>
                <span><span className="font-semibold text-blue-700 bg-blue-50 px-1.5 py-0.5 rounded">Fifth:</span> cumulative amount with COLA applied</span>
              </li>
              <li className="flex">
                <span className="mr-2">•</span>
                <span><span className="font-semibold text-blue-700 bg-blue-50 px-1.5 py-0.5 rounded">Sixth:</span> amount lost over time</span>
              </li>
              <li className="flex">
                <span className="mr-2">•</span>
                <span><span className="font-semibold text-blue-700 bg-blue-50 px-1.5 py-0.5 rounded">Seventh:</span> cumulative percentage lost of total pension payout</span>
              </li>
              <li className="flex">
                <span className="mr-2">•</span>
                <span><span className="font-semibold text-blue-700 bg-blue-50 px-1.5 py-0.5 rounded">Eighth:</span> diminished FAS without a COLA (Replacement Rate)</span>
              </li>
              <li className="flex">
                <span className="mr-2">•</span>
                <span><span className="font-semibold text-blue-700 bg-blue-50 px-1.5 py-0.5 rounded">Ninth:</span> diminished FAS with a simple COLA (Replacement Rate)</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InstructionsPanel;
