// components/ResultsTable.jsx
import React from 'react';

function ResultsTable({ results }) {
  return (
    <div className="mt-8 w-full">
      <div className="overflow-x-auto">
        <table className="w-full divide-y divide-gray-200 table-fixed">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-[6%]">Year</th>
              <th className="px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-[12%]">No COLA Pension<br/>Annual</th>
              <th className="px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-[12%]">No COLA Pension<br/>Total</th>
              <th className="px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-[11%]">Annual COLA<br/>Increase Amount</th>
              <th className="px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-[11%]">Total Pension<br/>with COLA</th>
              <th className="px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-[12%]">Amount Lost<br/>without COLA</th>
              <th className="px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-[12%]">Percent of Total<br/>Pension Lost</th>
              <th className="px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-[12%]">FAS Rate <span className="font-bold">without</span><br/>3% COLA</th>
              <th className="px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-[12%]">FAS Rate<br/>with COLA</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {results.map((row) => (
              <tr key={row.year}>
                <td className="px-2 py-1 whitespace-nowrap text-xs text-gray-900">
                  {row.year}
                </td>
                <td className="px-2 py-1 whitespace-nowrap text-xs text-gray-900">
                  {row.fixedAnnualPension.toLocaleString(undefined, { maximumFractionDigits: 0 })}
                </td>
                <td className="px-2 py-1 whitespace-nowrap text-xs text-gray-900">
                  {row.totalFixedPaid.toLocaleString(undefined, { maximumFractionDigits: 0 })}
                </td>
                <td className={`px-2 py-1 whitespace-nowrap text-xs ${row.totalColaPaid > row.totalFixedPaid ? 'text-green-600' : 'text-gray-900'}`}>
                  {row.colaAnnualPension.toLocaleString(undefined, { maximumFractionDigits: 0 })}
                </td>
                <td className={`px-2 py-1 whitespace-nowrap text-xs ${row.totalColaPaid > row.totalFixedPaid ? 'text-green-600' : 'text-gray-900'}`}>
                  {row.totalColaPaid.toLocaleString(undefined, { maximumFractionDigits: 0 })}
                </td>
                <td className={`px-2 py-1 whitespace-nowrap text-xs ${row.amountLost > 0 ? 'text-red-600' : 'text-gray-900'}`}>
                  {row.amountLost.toLocaleString(undefined, { maximumFractionDigits: 0 })}
                </td>
                <td className={`px-2 py-1 whitespace-nowrap text-xs ${parseFloat(row.percentLost) > 0 ? 'text-red-600' : 'text-gray-900'}`}>
                  {row.percentLost}%
                </td>
                <td className={`px-2 py-1 whitespace-nowrap text-xs ${row.year > 1 ? 'text-red-600' : 'text-gray-900'}`}>
                  {row.fasReplacement}%
                </td>
                <td className={`px-2 py-1 whitespace-nowrap text-xs ${row.year > 1 ? 'text-gray-900' : 'text-gray-900'}`}>
                  {row.fasColaReplacement}%
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ResultsTable;
