// components/SupplementaryInfo.jsx
function SupplementaryInfo() {
  return (
    <div className="space-y-4 sticky top-4">
      <div className="bg-yellow-50 rounded-lg p-4 shadow-lg">
        <h3 className="font-medium text-yellow-800 mb-2">Note:</h3>
        <ul className="space-y-2 text-yellow-800">
          <li className="flex">
            <span className="mr-2">•</span>
            <span>The COLA is calculated as simple, non-compounding.</span>
          </li>
          <li className="flex">
            <span className="mr-2">•</span>
            <span>This calculator demonstrates the effects of a 1% or 3% COLA loss over time.</span>
          </li>
          <li className="flex">
            <span className="mr-2">•</span>
            <span>Inflation is assumed at 3%.</span>
          </li>
          <li className="flex">
            <span className="mr-2">•</span>
            <span>There is no need to put in a $ sign, commas or % in the entry boxes.</span>
          </li>
          <li className="flex">
            <span className="mr-2">•</span>
            <span>The most important statistic is the diminished FAS (Replacement Rate).</span>
          </li>
        </ul>
      </div>

      <div className="bg-green-50 rounded-lg p-4 shadow-lg">
        <h3 className="font-medium text-green-800 mb-3">Try this Example:</h3>
        <div className="space-y-4 text-green-800">
          <div className="flex items-start space-x-3">
            <span className="font-medium">1.</span>
            <div>
              Enter <span className="font-mono bg-green-100 px-2 py-0.5 rounded">50000</span> as the base pension in the first box.
            </div>
          </div>

          <div className="flex items-start space-x-3">
            <span className="font-medium">2.</span>
            <div>
              Enter <span className="font-mono bg-green-100 px-2 py-0.5 rounded">3</span> in the second box, as this was the percent COLA promised to pre 2013 retirees.
            </div>
          </div>

          <div className="flex items-start space-x-3">
            <span className="font-medium">3.</span>
            <div>
              Enter <span className="font-mono bg-green-100 px-2 py-0.5 rounded">66</span> in the third box, as this was the full retirement (30 years) FAS prior to 2013.
            </div>
          </div>
        </div>
      </div>

      <div className="bg-red-50 rounded-lg p-4 shadow-lg">
        <h3 className="font-medium text-red-800 mb-2">What you will learn:</h3>
        <div className="text-red-800 space-y-4 text-sm">
          <p>
            In year 20 without a COLA, the above retiree will have lost $285,000, experienced a total pension loss of 22.18% compared to what was promised, and now lives on a replacement rate of 37.64%, which is below the rate typically paid out by Social Security (40%).
          </p>
          <p>
            In year 40 without a COLA, this same retiree is still receiving $50,000 annually, resulting in a total pension loss of $1,170,000, and now lives on a FAS of 20.84%. Had STRS fulfilled its COLA obligation, this same retiree would be receiving a yearly income of $108,500, with the FAS still at 66%.
          </p>
        </div>
      </div>
    </div>
  );
}

export default SupplementaryInfo;
