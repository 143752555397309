// components/PensionCalculator.jsx
import { useState } from 'react';

function PensionCalculator({ onCalculate }) {
  const [formData, setFormData] = useState({
    startingPension: '',
    annualIncrease: '',
    fasReplacementRate: ''
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    const startingPension = parseFloat(formData.startingPension);
    const annualIncrease = parseFloat(formData.annualIncrease) / 100;
    const fasReplacementRate = parseFloat(formData.fasReplacementRate) / 100;

    let results = [];
    let totalFixedPaid = 0;
    let totalColaPaid = 0;
    const fixedAnnualPension = startingPension;
    const INFLATION_RATE = 0.03; // 3% inflation

    for (let year = 1; year <= 40; year++) {
      totalFixedPaid += fixedAnnualPension;

      let colaAnnualPension;
      if (year === 1) {
        colaAnnualPension = fixedAnnualPension;
      } else {
        colaAnnualPension = fixedAnnualPension + (fixedAnnualPension * annualIncrease * (year - 1));
      }

      totalColaPaid += colaAnnualPension;
      const amountLost = totalColaPaid - totalFixedPaid;
      const percentLost = ((amountLost / totalColaPaid) * 100).toFixed(2);

      // Original FAS Replacement calculation
      const purchasingPowerFactor = 1 / Math.pow(1.03, year - 1);
      const fasReplacement = fasReplacementRate * purchasingPowerFactor;

      // New FAS COLA Replacement Rate calculation
      // Using the difference between inflation and COLA rate
      const netInflationImpact = INFLATION_RATE - annualIncrease;
      const colaAdjustedPurchasingPower = 1 / Math.pow(1 + netInflationImpact, year - 1);
      const fasColaReplacement = fasReplacementRate * colaAdjustedPurchasingPower;

      results.push({
        year,
        fixedAnnualPension,
        totalFixedPaid,
        colaAnnualPension,
        totalColaPaid,
        amountLost,
        percentLost,
        fasReplacement: (fasReplacement * 100).toFixed(2),
        fasColaReplacement: (fasColaReplacement * 100).toFixed(2)
      });
    }

    onCalculate(results);
  };

  const validateNumberInput = (e) => {
    const value = e.target.value.replace(/[^0-9.]/g, '');
    const parts = value.split('.');
    if (parts.length > 2) {
      return parts[0] + '.' + parts[1];
    }
    return value;
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div className="space-y-4">
        <div>
          <label 
            htmlFor="startingPension" 
            className="block text-sm font-medium text-gray-700 mb-2"
          >
            Annual Base Amount:
          </label>
          <input
            type="text"
            id="startingPension"
            value={formData.startingPension}
            onChange={(e) => setFormData({
              ...formData, 
              startingPension: validateNumberInput(e)
            })}
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
            required
          />
        </div>

        <div>
          <label 
            htmlFor="annualIncrease" 
            className="block text-sm font-medium text-gray-700 mb-2"
          >
            Annual COLA Increase (%):
          </label>
          <input
            type="text"
            id="annualIncrease"
            value={formData.annualIncrease}
            onChange={(e) => setFormData({
              ...formData, 
              annualIncrease: validateNumberInput(e)
            })}
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
            required
          />
        </div>

        <div>
          <label 
            htmlFor="fasReplacementRate" 
            className="block text-sm font-medium text-gray-700 mb-2"
          >
            Original % FAS (Replacement Rate):
          </label>
          <input
            type="text"
            id="fasReplacementRate"
            value={formData.fasReplacementRate}
            onChange={(e) => setFormData({
              ...formData, 
              fasReplacementRate: validateNumberInput(e)
            })}
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
            required
          />
        </div>
      </div>

      <button
        type="submit"
        className="w-full bg-blue-600 text-white py-3 px-6 rounded-md hover:bg-blue-700 transition duration-200 text-lg font-medium"
      >
        Generate Table
      </button>
    </form>
  );
}

export default PensionCalculator;
